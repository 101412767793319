import styled from "styled-components";
import tw from "tailwind-styled-components";

const StyledLearnArticleBox = styled.div`
  border-radius: 50px;
`;

export const LearnArticleBox = tw(StyledLearnArticleBox)`
  bg-interface-100
  p-[2.75rem]
`;
