import * as React from "react";

import { Box } from "@components/box";
import { GatsbyLink } from "@components/gatsby-link";
import { GrayPhrase, Paragraph, Subheading } from "@components/typography";

import { LearnArticleBox } from "../styles";
import { LearnArticleProps } from "../types";

export const LearnArticle = (props: LearnArticleProps) => (
  <LearnArticleBox>
    <Subheading>{props.title}</Subheading>
    <Box className="py-[1.5rem]">
      <Paragraph>
        <GrayPhrase>{props.description}</GrayPhrase>
      </Paragraph>
    </Box>
    <GatsbyLink to={props.slug}>Learn more</GatsbyLink>
  </LearnArticleBox>
);
