import * as React from "react";
import { graphql } from "gatsby";
import { BLOCKS } from "@contentful/rich-text-types";
import { renderRichText } from "gatsby-source-contentful/rich-text";

import { Box } from "@components/box";
import { LearnArticle } from "@components/learn-article";
import { LearnArticleContainer } from "@components/learn-article-container";
import { PageLayout, SEO } from "@components/page-layout";
import { ShareArticle } from "@components/share-article";
import {
  Heading,
  PageHeading,
  Paragraph,
  Subheading,
} from "@components/typography";
import { GatsbyLink } from "@components/gatsby-link";

import arrowRight from "@images/ic-arrow-long-right-gray.svg";

const serializeLinks = (data: any) => {
  let links: {
    title: string;
    slug: string;
    description: string;
    active: boolean;
  }[] = [];

  data.nodes.forEach((link: any) => {
    links.push({
      title: link.title,
      slug: link.slug,
      description: link.description.description,
      active: false,
    });
  });

  return links;
};

const LearnSectionTemplate = (props: any) => {
  const { contentfulLearnSection, allContentfulLearnArticle }: any = props.data;

  const links = serializeLinks(allContentfulLearnArticle);

  const options = {
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: (node: any) => {
        const imageID = node.data.target.sys.id;
        const {
          file: { url },
        } = contentfulLearnSection.body.references.find(
          ({ contentful_id }: { contentful_id: string | number }) =>
            contentful_id === imageID
        );

        return (
          <Box display="flex" className="w-full justify-center p-[2rem]">
            <img width="60%" src={url} />
          </Box>
        );
      },
      [BLOCKS.HEADING_1]: (_node: any, children: any) => (
        <Box className="p-[2rem]">
          <PageHeading>{children}</PageHeading>
        </Box>
      ),
      [BLOCKS.HEADING_2]: (_node: any, children: any) => (
        <Box className="p-[1.5rem]">
          <Heading>{children}</Heading>
        </Box>
      ),
      [BLOCKS.HEADING_3]: (_node: any, children: any) => (
        <Box className="p-[1rem]">
          <Subheading>{children}</Subheading>
        </Box>
      ),
      [BLOCKS.PARAGRAPH]: (_node: any, children: any) => (
        <Box className="p-[0.75rem]">
          <Paragraph>{children}</Paragraph>
        </Box>
      ),
      [BLOCKS.UL_LIST]: (_node: any, children: any) => <ul>{children}</ul>,
    },
  };

  return (
    <PageLayout>
      <SEO title={contentfulLearnSection.title} />
      <Box
        display="flex"
        className="justify-center sm:justify-start h-[92px] md:h-[61px] mb-[66px] bg-interface-100"
      >
        <Box
          display="flex"
          className="justify-between items-center text-[17px] w-full max-w-[1320px] md:text-[14px] md:w-full md:px-[2rem] sm:w-auto"
        >
          <Box
            display="flex"
            className="flex-row whitespace-nowrap overflow-hidden"
            styledCss="text-overflow: ellipsis;"
          >
            <GatsbyLink to="/learn">Learn</GatsbyLink>
            <img src={arrowRight} className="mx-[11px]" />
            <Paragraph>{contentfulLearnSection.title}</Paragraph>
          </Box>
          <Box sm={{ display: "hidden" }}>
            <ShareArticle />
          </Box>
        </Box>
      </Box>
      <LearnArticleContainer
        title={contentfulLearnSection.title}
        sectionSlug={contentfulLearnSection.slug}
      >
        {renderRichText(contentfulLearnSection.body, options)}
        <Box display="flex" className="flex-col space-y-1 pt-[2.75rem]">
          {links.map((link, key) => (
            <LearnArticle key={key} {...link} />
          ))}
        </Box>
      </LearnArticleContainer>
    </PageLayout>
  );
};

export default LearnSectionTemplate;

export const pageQuery = graphql`
  query LearnSectionBySlug($slug: String!) {
    contentfulLearnSection(slug: { eq: $slug }) {
      body {
        raw
      }
      slug
      title
    }
    allContentfulLearnArticle(filter: { section: { slug: { eq: $slug } } }) {
      nodes {
        slug
        title
        description {
          description
        }
      }
    }
  }
`;
